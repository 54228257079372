.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-body {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #268bd2;
}

.App-link {
    color: #61dafb;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.input-container {
    /* display: flex;
    flex-direction: row;
    align-items: space-evenly;
    justify-content: center; */
    /* padding-right: 2%; */
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 4%;
    padding-right: 4%;
    width: 100%;
    min-height: 105px;
}

.input {
    font-size: larger;
    background-color: white;
    border-radius: 15px;
    max-width: 300px;
    height: 50px;
    padding: 8%;
    /* margin: 2%; */
    border: none;
    border-color: transparent;
}
.input:focus {
    outline: none;
    background-color: whitesmoke;
}

.input:hover {
    outline: none;
    background-color: whitesmoke;
}

code {
    font-weight: bold;
}
